@import '~@fortawesome/fontawesome-free/css/all.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

::placeholder {
    @apply text-orlyGrey;
}

::-ms-input-placeholder {
    @apply text-orlyGrey;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    @apply text-orlyGrey-darkest;
}

body>header,
body>footer {
    flex: 0 1 auto;
}

body>#main {
    flex: 1 1 auto;
}

input{
    @apply text-orlyGrey-darkest p-2 rounded-lg;
}

input.minInput{
    width: 50px;
    height: 25px;
}

.required:after {
    content:" *";
    color: red;
}

.ml-16pc{
    margin-left: 16.666666%;
}

.grecaptcha-badge{
    bottom: 42px !important;
}

.button-principal {
    @apply bg-gold text-orlyGrey-darkest py-2 px-3 rounded-lg font-bold hover:bg-orlyGrey-lightest hover:text-white;
}

.catalog-button {
    @apply bg-orlyGrey-darkest text-white py-2 px-3 rounded-lg font-bold hover:bg-orlyGrey hover:text-orlyGrey-darkest;
}

.account-menu{
    @apply flex h-full relative rounded-lg hover:bg-gold hover:text-orlyGrey-darkest
}

input[type=checkbox].switch{
    height: 0;
    width: 0;
    visibility: hidden;
}

label.switch-label {
    @apply bg-orlyGrey-lighter block cursor-pointer rounded-full relative;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    position: relative;
}

label.switch-label:after {
    @apply bg-orlyGrey block cursor-pointer rounded-full absolute;
    content: '';
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    transition: 0.3s;
}

input.switch:checked + label.switch-label {
    @apply bg-gold;
}

input.switch:checked + label.switch-label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

label.switch-label:active:after {
    width: 20px;
}

select, option{
    @apply text-orlyGrey-darker;
}

h1{
    text-transform: uppercase;
    @apply text-gold text-4xl text-center;
}

h2{
    font-weight: normal;
    margin-bottom: 0;
    @apply text-gold-light text-2xl text-center;
}

h3{
    font-weight: normal;
}

.max-w-xxxs{
    max-width: 7rem;
}

.fa{
    font-size: 32px;
    padding-right: 15px;
}

.text-xs{
    font-size: 0.75rem;
    line-height: 1rem;
}

.text-xxs{
    font-size: 0.625rem;
    line-height: 0.875rem;
}

.text-xxxs{
    font-size: 0.5rem;
    line-height: 0.75rem;
}

#products{
    @apply bg-red-500 rounded-full text-xxs absolute py-px px-2;
    left: 50px;
    bottom: 10px;
}

.loader {
    border: 16px solid #2d2d2d;
    border-top: 16px solid #c99d47;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.z-90{
    z-index: 90;
}
@media (min-width: 1024px) {
    lg\:hidden {
        display: none;
    }
}
@media (max-width: 1024px) {
    .h-1\/4 {
        height: 25%;
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 1440px) {
    label.switch-label {
        height: 20px;
        width: 35px;
    }

    label.switch-label:after {
        top: 3px;
        left: 3px;
        width: 14px;
        height: 14px;
    }

    #products{
        @apply px-1;
        font-size: 0.5rem;
        line-height: 0.5rem;
        left: 40px;
        bottom: 5px;
    }

    #search_query, #priceFilterMini, #priceFilterMax{
        @apply p-1 h-1/2;
    }

    #search_submit, #PriceFilter, #ResetPriceFilter{
        @apply py-1;
    }
}